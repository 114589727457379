import Vue from 'vue'

// axios
import axios from 'axios'
import {initialAbility} from "@/libs/acl/config";
import ability from "@/libs/acl/ability";
import router from "@/router";

import { useLocalStorage } from "@/composition/useLocalStorage";

const { getLSItem, removeLSItem } = useLocalStorage()
const cancelTokens = {};

function handleCancelToken(config) {
    const requestId = config.method + config.url + JSON.stringify(config.data);

    if (cancelTokens[requestId]) {
        cancelTokens[requestId].cancel('Duplicate request');
    }

    const cancelToken = axios.CancelToken.source();
    cancelTokens[requestId] = cancelToken;

    config.cancelToken = cancelToken.token;
}

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_PROXY_URL,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
});

axiosIns.interceptors.request.use(function (config) {
    const userToken =  getLSItem('sessionId');
    const businessId = getLSItem('business-id');

    handleCancelToken(config);

    return {
        ...config,
        headers: {
            'X-API-KEY': process.env.VUE_APP_X_API_KEY,
            ...(userToken && { 'X-USER-TOKEN': userToken }),
            ...(businessId && { 'X-BUSINESS-ID': businessId }),
        }
    };
}, function (error) {
    return Promise.reject(error);
});

axiosIns.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    if (error?.response?.status === 401) {
        removeLSItem('sessionId');
        removeLSItem('userData');
        removeLSItem('businesses');
        removeLSItem('business-id');
        ability.update(initialAbility);

        router.push({ name: 'auth-login' });
    }

    return Promise.reject(error);
});

Vue.prototype.$http = axiosIns

export default axiosIns
