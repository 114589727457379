import {HISTORY_ACCESS} from '@/roles/access';

export default [{
        path: '/apps/calendar',
        name: 'apps-calendar',
        component: () =>
            import ('@/views/apps/calendar/Calendar.vue'),
    },

    // *===============================================---*
    // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
    // *===============================================---*
    {
        path: '/apps/email',
        name: 'apps-email',
        component: () =>
            import ('@/views/apps/email/Email.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
        },
    },
    {
        path: '/apps/email/:folder',
        name: 'apps-email-folder',
        component: () =>
            import ('@/views/apps/email/Email.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
            navActiveLink: 'apps-email',
        },
        beforeEnter(to, _, next) {
            if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
            else next({ name: 'error-404' })
        },
    },
    {
        path: '/apps/email/label/:label',
        name: 'apps-email-label',
        component: () =>
            import ('@/views/apps/email/Email.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
            navActiveLink: 'apps-email',
        },
        beforeEnter(to, _, next) {
            if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
            else next({ name: 'error-404' })
        },
    },

    // *===============================================---*
    // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
    // *===============================================---*
    {
        path: '/apps/todo',
        name: 'apps-todo',
        component: () =>
            import ('@/views/apps/todo/Todo.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'todo-application',
        },
    },
    {
        path: '/apps/todo/:filter',
        name: 'apps-todo-filter',
        component: () =>
            import ('@/views/apps/todo/Todo.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'todo-application',
            navActiveLink: 'apps-todo',
        },
        beforeEnter(to, _, next) {
            if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
            else next({ name: 'error-404' })
        },
    },
    {
        path: '/apps/todo/tag/:tag',
        name: 'apps-todo-tag',
        component: () =>
            import ('@/views/apps/todo/Todo.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'todo-application',
            navActiveLink: 'apps-todo',
        },
        beforeEnter(to, _, next) {
            if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
            else next({ name: 'error-404' })
        },
    },

    // *===============================================---*
    // *--------- CHAT  ---------------------------------------*
    // *===============================================---*
    {
        path: '/apps/chat',
        name: 'apps-chat',
        component: () =>
            import ('@/views/apps/chat/Chat.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'chat-application',
        },
    },

    // *===============================================---*
    // *--------- ECOMMERCE  ---------------------------------------*
    // *===============================================---*
    {
        path: '/apps/e-commerce/shop',
        name: 'apps-e-commerce-shop',
        component: () =>
            import ('@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue'),
        meta: {
            contentRenderer: 'sidebar-left-detached',
            contentClass: 'ecommerce-application',
            pageTitle: 'Shop',
            breadcrumb: [{
                    text: 'ECommerce',
                },
                {
                    text: 'Shop',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/apps/e-commerce/wishlist',
        name: 'apps-e-commerce-wishlist',
        component: () =>
            import ('@/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue'),
        meta: {
            pageTitle: 'Wishlist',
            contentClass: 'ecommerce-application',
            breadcrumb: [{
                    text: 'ECommerce',
                },
                {
                    text: 'Wishlist',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/apps/e-commerce/checkout',
        name: 'apps-e-commerce-checkout',
        component: () =>
            import ('@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
        meta: {
            pageTitle: 'Checkout',
            contentClass: 'ecommerce-application',
            breadcrumb: [{
                    text: 'ECommerce',
                },
                {
                    text: 'Checkout',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/apps/e-commerce/:slug',
        name: 'apps-e-commerce-product-details',
        component: () =>
            import ('@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'),
        meta: {
            pageTitle: 'Product Details',
            contentClass: 'ecommerce-application',
            breadcrumb: [{
                    text: 'ECommerce',
                },
                {
                    text: 'Shop',
                    active: true,
                },
                {
                    text: 'Product Details',
                    active: true,
                },
            ],
        },
    },

    // *===============================================---*
    // *--------- USER ---- ---------------------------------------*
    // *===============================================---*
    {
        path: '/apps/users/list',
        name: 'apps-users-list',
        component: () =>
            import ('@/views/apps/user/users-list/UsersList.vue'),
        meta: {
            resource: 'Users',
        }
    },
    {
        path: '/apps/users/view/:id',
        name: 'apps-users-view',
        component: () =>
            import ('@/views/apps/user/users-view/UsersView.vue'),
        meta: {
            resource: 'Users',
        }
    },
    {
        path: '/apps/users/edit/:id',
        name: 'apps-users-edit',
        component: () =>
            import ('@/views/apps/user/users-edit/UsersEdit.vue'),
        meta: {
            pageTitle: 'Користувач',
            breadcrumb: [{
                    text: 'Користувачі',
                    to: '/apps/users/list',
                },
                {
                    text: 'Користувач',
                    active: true,
                },
            ],
            navActiveLink: 'pages-knowledge-base',
            resource: 'Users',
        },
    },

    // *===============================================---*
    // *--------- DRIVER ---- ---------------------------------------*
    // *===============================================---*
    {
        path: '/apps/drivers/list',
        name: 'apps-drivers-list',
        component: () =>
            import ('@/views/apps/driver/drivers-list/DriversList.vue'),
    },
    {
        path: '/apps/drivers/view/:id',
        name: 'apps-drivers-view',
        component: () =>
            import ('@/views/apps/driver/drivers-view/DriversView.vue'),
        meta: {
            pageTitle: 'Водій',
            breadcrumb: [{
                    text: 'Водії',
                    to: '/apps/drivers/list',
                },
                {
                    text: 'Водій',
                    active: true,
                },
            ],
            navActiveLink: 'pages-knowledge-base',
        },
    },
    {
        path: '/apps/drivers/edit/:id',
        name: 'apps-drivers-edit',
        component: () =>
            import ('@/views/apps/driver/drivers-edit/DriversEdit.vue'),
        meta: {
            pageTitle: 'Водій',
            breadcrumb: [{
                    text: 'Водії',
                    to: '/apps/drivers/list',
                },
                {
                    text: 'Водій',
                    active: true,
                },
            ],
            navActiveLink: 'pages-knowledge-base',
        },
    },
    {
        path: '/apps/drivers/contact',
        name: 'apps-drivers-list-contact',
        component: () =>
            import ('@/views/apps/driver/drivers-list/DriversListContact.vue'),
    },
    {
        path: '/apps/drivers/contact/:id',
        name: 'apps-drivers-contact',
        component: () =>
            import ('@/views/apps/driver/drivers-contact/DriversContact.vue'),
    },

    // *===============================================---*
    // *--------- GROUP ---- ---------------------------------------*
    // *===============================================---*
    {
        path: '/apps/group/list',
        name: 'apps-groups-list',
        component: () =>
            import ('@/views/apps/group/group-list/GroupsList.vue'),
    },
    {
        path: '/apps/group/view/:id',
        name: 'apps-groups-view',
        component: () =>
            import ('@/views/apps/group/group-view/GroupsView.vue'),
        meta: {
            pageTitle: 'Група',
            breadcrumb: [{
                    text: 'Групи водіїв',
                    to: '/apps/group/list',
                },
                {
                    text: 'Група',
                    active: true,
                },
            ],
            navActiveLink: 'pages-knowledge-base',
        },
    },
    {
        path: '/apps/group/edit/:id',
        name: 'apps-groups-edit',
        component: () =>
            import ('@/views/apps/group/group-edit/GroupsEdit.vue'),
        meta: {
            pageTitle: 'Група',
            breadcrumb: [{
                    text: 'Групи водіїв',
                    to: '/apps/group/list',
                },
                {
                    text: 'Група',
                    active: true,
                },
            ],
            navActiveLink: 'pages-knowledge-base',
        },
    },
    {
        path: '/apps/group/contact/:id',
        name: 'apps-groups-contact',
        component: () =>
            import ('@/views/apps/group/group-contact/GroupsContact.vue'),
    },

    // Balance
    {
        path: '/apps/balance/list',
        name: 'apps-balance-list',
        component: () =>
            import ('@/views/apps/balance/balance-list/BalanceList.vue'),
    },
    {
        path: '/apps/balance/preview/:id',
        name: 'apps-balance-preview',
        component: () =>
            import ('@/views/apps/balance/balance-preview/BalancePreview.vue'),
        meta: {
            pageTitle: 'Транзакція',
            breadcrumb: [{
                    text: 'Транзакції',
                    to: '/apps/balance/list',
                },
                {
                    text: 'Транзакція',
                    active: true,
                },
            ],
            navActiveLink: 'pages-knowledge-base',
        },
    },
    {
        path: '/apps/balance/add/',
        name: 'apps-balance-add',
        component: () =>
            import ('@/views/apps/balance/balance-add/BalanceAdd.vue'),
    },
    {
        path: '/apps/balance/edit/:id',
        name: 'apps-balance-edit',
        component: () =>
            import ('@/views/apps/balance/balance-edit/BalanceEdit.vue'),
    },

    // Fuel
    {
        path: '/apps/fuel/list',
        name: 'apps-fuel-list',
        component: () =>
            import ('@/views/apps/fuel/fuel-list/FuelList.vue'),
    },
    {
        path: '/apps/fuel/preview/:id',
        name: 'apps-fuel-preview',
        component: () =>
            import ('@/views/apps/fuel/fuel-preview/FuelPreview.vue'),
        meta: {
            pageTitle: 'Транзакція',
            breadcrumb: [{
                    text: 'Транзакції',
                    to: '/apps/fuel/list',
                },
                {
                    text: 'Транзакція',
                    active: true,
                },
            ],
            navActiveLink: 'pages-knowledge-base',
        },
    },
    {
        path: '/apps/fuel/add/',
        name: 'apps-fuel-add',
        component: () =>
            import ('@/views/apps/fuel/fuel-add/FuelAdd.vue'),
    },
    {
        path: '/apps/fuel/edit/:id',
        name: 'apps-fuel-edit',
        component: () =>
            import ('@/views/apps/fuel/fuel-edit/FuelEdit.vue'),
    },

    // Import

    {
        path: '/apps/import',
        name: 'apps-import',
        component: () =>
            import ('@/views/apps/import/ImportExcel.vue'),
    },

    // Invoice
    {
        path: '/apps/invoice/list',
        name: 'apps-invoice-list',
        component: () =>
            import ('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
    },
    {
        path: '/apps/invoice/preview/:id',
        name: 'apps-invoice-preview',
        component: () =>
            import ('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
        meta: {
            pageTitle: 'Інвойс',
            breadcrumb: [{
                    text: 'Інвойси',
                    to: '/apps/invoice/list',
                },
                {
                    text: 'Інвойс',
                    active: true,
                },
            ],
        }
    },
    {
        path: '/apps/invoice/add/',
        name: 'apps-invoice-add',
        component: () =>
            import ('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
    },
    {
        path: '/apps/invoice/edit/:id',
        name: 'apps-invoice-edit',
        component: () =>
            import ('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
    },

    // accounts

    {
        path: '/apps/accounts/',
        name: 'apps-accounts',
        component: () =>
            import ('@/views/apps/acquiring/AcquiringView.vue'),
    },

    // *===============================================---*
    // *--------- DISCOUNTS -------------------------------------------*
    // *===============================================---*

    {
        path: '/apps/discounts/',
        name: 'discounts',
        component: () =>
            import ('@/views/apps/discounts/DiscountsView.vue'),
        meta: {
            pageTitle: 'Шаблони знижок',
        },
    },
    {
        path: '/apps/discounts/:id',
        name: 'discount',
        component: () =>
            import ('@/views/apps/discounts/DiscountView.vue'),
        meta: {
            pageTitle: 'Шаблон',
            breadcrumb: [{
                    text: 'Шаблони знижок',
                    to: '/apps/discounts/',
                },
                {
                    text: 'Шаблон',
                    active: true,
                },
            ],
            navActiveLink: 'discounts',
        },
    },
    {
        path: '/apps/discounts-all/',
        name: 'discounts-all',
        component: () =>
            import ('@/views/apps/discounts/AllDiscountsView.vue'),
        meta: {
            pageTitle: 'Налаштування знижок',
        },
    },
    {
        path: '/apps/discounts-changes/',
        name: 'discounts-changes',
        component: () =>
            import ('@/views/apps/discounts/DiscountsChangesView.vue'),
        meta: {
            pageTitle: 'Завдання та історія змін',
        },
    },

    // *===============================================---*
    // *--------- LOGS -------------------------------------------*
    // *===============================================---*

    {
        path: '/apps/logs/',
        name: 'logs',
        component: () =>
            import ('@/views/apps/logs/LogsView.vue'),
        meta: {
            pageTitle: 'Історія',
            roles: HISTORY_ACCESS
        },
    },
];
