import BusinessSettingsService from "@/services/businessSettings/businessSettings.service";

export const businessSettings = {
    namespaced: true,

    state: {
        common: null,
        commonFetch: null,
        notifiers: null,
    },

    actions: {
        fetchCommonSettings({ state, commit }) {

            if(state.commonFetch) {
                return state.commonFetch;
            }

            let promise = BusinessSettingsService.common()
                .then(
                    (response) => {
                        commit("fetchCommonSuccess", response.data);
                        return Promise.resolve(response);
                    },
                )
                .catch(error => Promise.reject(error))
                .finally(() => commit("SER_PROMISE", null))

            commit("SER_PROMISE", promise)

            return promise;
        },
    },

    mutations: {
        fetchCommonSuccess(state, data) {
            state.common = data;
        },
        SER_PROMISE(state, promise) {
            state.commonFetch = promise
        }
    },

    getters: {
        GET_COMMON_SETTINGS(state) {
            return state.common;
        },
        GET_ALLOWED_PAYMENT_TYPES(state) {
            return state.common?.settings.accounts.allowed.types.map(type => type.typeId);
        },
        GET_DEFAULT_DISCOUNTS(state) {
            return state.common?.settings.discounts.defaults;
        },
        GET_COMMON_NOTIFIERS(state) {
            return state.common?.settings.notifiers;
        },
        GET_CURRENT_DISCOUNTS(state) {
            return state.common?.settings.discounts.current;
        },
        GET_COMPANY_TYPE(state) {
            return state.common?.typeCompany;
        }
    },
};
