export default [{
        path: '/dashboard/analytics',
        name: 'dashboard-analytics',
        component: () =>
            import ('@/views/dashboard/analytics/Analytics.vue'),
        meta: {
            resource: 'Analitycs',
        },
    },
    {
        path: '/dashboard/home',
        name: 'dashboard-ecommerce',
        component: () =>
            import ('@/views/dashboard/home/Home.vue'),
        meta: {
            resource: 'Home',
        },
    },
]