export const useLocalStorage = () => {
    function getLSItem(key) {
        return localStorage.getItem(key)
    }
    function setLSItem(key, value) {
        localStorage.setItem(key, value)
    }
    function removeLSItem(key) {
        localStorage.removeItem(key);
    }

    return {
        getLSItem,
        setLSItem,
        removeLSItem,
    }
}
